#menu-button {
  display: none;
}
#signOutButton {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
}

@media screen and (max-width: 1200px) {
  #menu-button {
    display: block;
    z-index: 11;
  }
  #signOutButton {
    bottom: 70px;
  }
}
