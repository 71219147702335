/*@import "~antd/dist/antd.less";*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#content {
  margin-left: 15em;
  background: white;
  padding: 1.5em 7em;
}

@media screen and (max-width: 1200px) {
  #content {
    margin-left: 0;
    padding: 1.5em 1.5em !important;
  }
}

div {
  max-width: 1500px;
}
